import Axios from "axios";

const REQUEST_TIMEOUT = 35000;
const TIMEOUT_MESSAGE = "Request timeouted";

import { getToken } from "../utils/getToken";
import { getPasswordToken } from "utils/sign-in/getPasswordToken";

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: REQUEST_TIMEOUT,
  timeoutErrorMessage: TIMEOUT_MESSAGE,
});
const token = getPasswordToken() || getToken();

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      if (!!getPasswordToken() || window.location.pathname == '/login') {
        return
      }

      window.location.assign('/login')
      localStorage.removeItem('organization_token')

      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

if (token) {
  instance.defaults.headers.common["Authorization"] = token;
}

const PATHS = {
  GRAPHICS: {
    GET: "/all",
  },
};

const Http = {
  instance,
  PATHS,
};

export default Http;
